<template>
  <page-container title="代码条目" :breadcrumb="breadcrumb">
    <div class="time-monitoring">
      <div class="tab-container tab-bottom0 tab-left0">
        <a-tabs v-model="activeKey" size="small">
          <a-tab-pane key="tab1" tab="基本代码条目"></a-tab-pane>
<!--          <a-tab-pane key="tab2" tab="第三方代码条目"></a-tab-pane>-->
        </a-tabs>
      </div>
      <base-code v-if="activeKey == 'tab1'"></base-code>
<!--      <third-code v-if="activeKey == 'tab2'"></third-code>-->
    </div>
  </page-container>
</template>
<script>
import BaseCode from './BaseCode/Index'
import ThirdCode from './ThirdCode/Index'
export default {
  name: 'liftCodeItems',
  components: {
    BaseCode,
    ThirdCode,
  },
  data() {
    return {
      breadcrumb: [
        {
          name: '设备设施物联网',
          path: ''
        },
        {
          name: '电梯物联网历史',
          path: ''
        },
        {
          name: '代码条目',
          path: ''
        },
      ],
      activeKey: 'tab1'
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log('=beforeRouteEnter=')
  //   next();
  // },
  // beforeRouteLeave(to, from, next) {
  //   console.log('=beforeRouteLeave=')
  //   next();
  // },
  created() {
    // console.log('=created=')
    // this.$ws.connect((ws) => {
    //   ws.send('{"devicenum":"001"}')
    // });
  },
  beforeDestroy() {
    // console.log('=beforeDestroy=')
    // this.$ws.disconnect();
  },
  methods: {
    // clickLeftMenu(event) {
    //   this.tabKey = event.key
    // },
  }
}
</script>

<style lang="scss" scoped>
.tab-container {
  padding: 0 24px;
  background-color: white;
}
.main-content {
  padding: 24px;
}
</style>