<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
             <a-form-model-item label="参数代码" prop="eventcode">
              <a-input v-model.trim="queryParams.eventcode" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="参数类型" prop="eventtype">
              <a-select v-model="queryParams.eventtype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in liftEventOptions" :key="index" :value="item.key">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
<!--            <a-form-model-item label="电梯类型" prop="liftpropertyid">-->
<!--              <a-select v-model="queryParams.liftpropertyid">-->
<!--                <a-select-option value="">请选择</a-select-option>-->
<!--                <a-select-option v-for="(item, index) in liftpropertyidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="事件名称" prop="eventname">
              <a-input v-model.trim="queryParams.eventname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">代码条目</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="eventid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventtype" slot-scope="value">{{eventTips[value]}}</span>
          <span slot="property" slot-scope="value"><a-tooltip :title="value"><span>{{value}}</span></a-tooltip></span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="modify(value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :liftpropertyidOptions="liftpropertyidOptions" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </div>
</template>

<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import pagination from '@/mixins/pagination'
import {eventTips} from '@/json/wlwhistory'
import { getLiftListByCondition } from 'A/jcgn'
import { getLiftEventListByCondition, deleteLiftEvent } from 'A/wlw'
import AddOrEditModal from './AddOrEditModal'
import {liftEventOptions} from "../../../../../../json/wlwhistory";
export default {
  name: 'repairItem',
  mixins: [pagination],
  components: {
    AddOrEditModal
  },
  data() {
    return {
      showAdvanced: false,
      liftEventOptions,
      eventTips,
      liftpropertyidOptions: [],
      liftpropertyidObj:{},
      queryParams: {
        liftpropertyid: '',
        eventtype: '',
        eventcode: '',
        evnentname:'',
      },
      tableColumns: [
        {
          title: '参数代码',
          dataIndex: 'eventcode',
          key: 'eventcode',
          ellipsis: true,
        },
        {
          title: '参数名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
        },
        {
          title: '参数类型',
          dataIndex: 'eventtype',
          key: 'eventtype',
          ellipsis: true,
          scopedSlots: { customRender: 'eventtype' }
        },
        // {
        //   title: '电梯类型',
        //   dataIndex: 'liftpropertyidsName',
        //   key: 'liftpropertyidsName',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'property' }
        // },
        {
          title: '参数描述',
          dataIndex: 'eventdesc',
          key: 'eventdesc',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalShowType: 'add',
      modalVisible: false,
      modalDetailData: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.initLiftpropertyidOptions();
      this.getTableData();
    },
    initLiftpropertyidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.liftpropertyidOptions = res.item;
          this.liftpropertyidObj = this.liftpropertyidOptions.reduce((a,b)=>{
            a[b.liftpropertyid] = b.value
            return a
          },{})
          this.getTableData();
        }
      })
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLiftEventListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(v=>{
            let arr = v.liftpropertyids.split(",")
            let liftpropertyidsName = arr.map(v=>this.liftpropertyidObj[v]).join(",")
            return {
              ...v,
              liftpropertyidsName
            }
          });
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventid);
      }).catch(()=>{
      });
    },
    delete(eventid) {
      this.showLoading();
      if(eventid) {
        let params = {
          eventid
        };
        deleteLiftEvent(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    add() {
      this.modalShowType = 'add';
      this.modalDetailData = null;
      this.modalVisible = true;
    },
    modify(value, record) {
      this.modalShowType = 'edit';
      this.modalDetailData = record;
      this.modalVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>